import React from "react";
import { ReactTyped } from "react-typed";

const Banner = () => {
  return (
    <div className="bg-[#2699fb] w-full py-[50px]">
      <div className="max-w-[1240px] my-[100px] mx-auto text-center font-bold">
        <div className=" text-xl md:text-3xl md:p-[24px]">Learn With Us</div>
        <h2 className="text-white text-3xl md:text-[70px] md:p-[24px]">
          Grow With Us
        </h2>
        <div className="text-[20px] md:text-[50px] md:p-[24px] text-white ">
          Learn 
          <ReactTyped className="pl-2"
            strings={[
              "Web Development",
              "Graphics Designing",
              "Digital Marketing",
              "MERN Development"
            ]}
            typeSpeed={100}
            backSpeed={70}
            loop={true}
          />
        </div>
        <button className='bg-black text-white p-3 rounded'>Get Started</button>
      </div>
    </div>
  );
};

export default Banner;
